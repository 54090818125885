<template>
  <div class="tabbar-wrap">
    <van-tabbar v-model="active" :placeholder="true" active-color="#306EF8" inactive-color="#6E7370">
      <van-tabbar-item :name="item.link" v-for="(item, index) in navTabBar" :key="index" :to="item.link">
        <span>{{ item.name }}</span>
        <template #icon="props">
          <img :src="props.active ? item.active : item.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <div @click="home">
      <div class="home">
        <img src="@/assets/images/homeButton.png" />
        <span>首页</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tabbar',
  data() {
    return {
      active: this.$route.name,
      navTabBar: [
        {
          badge: '',
          name: '首页',
          active: require('@/assets/images/index-active.png'),
          inactive: require('@/assets/images/index-inactive.png'),
          link: 'index'
        },
        {
          badge: '',
          name: '短信模板',
          active: require('@/assets/images/message-active.png'),
          inactive: require('@/assets/images/message-inactive.png'),
          link: 'Message'
        },
        {
          badge: '',
          name: '定时发送',
          active: require('@/assets/images/send-active.png'),
          inactive: require('@/assets/images/send-inactive.png'),
          link: 'send'
        },
        {
          badge:'',
          name:'在线充值',
          active:require("@/assets/images/pay-active.png"),
          inactive:require("@/assets/images/pay-inactive.png"),
          link: "pay"
        }
      ]
    };
  },
  watch: {
    '$route.name': {
      handler: function(newVal, oldVal) {
        this.active=newVal
      }
    }
  },
  methods: {
    home() {
      location.href = location.protocol + '//' + location.host;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.van-tabbar__placeholder {
  height: 98px !important;
  .van-tabbar {
    height: 98px;
    .van-tabbar-item__icon {
      margin-bottom: 8px;
      img {
        height: 40px;
      }
    }
    .van-tabbar-item__text {
      font-size: 24px;
    }
  }
}
.home {
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
  position: fixed;
  right: 20px;
  bottom: 300px;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background: #ff7c4a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  img {
    width: 27px;
    height: 27px;
  }
  span {
    font-size: 18px;
  }
}
</style>
